import Text from '@/components/basic/text';
import theme from '@/style';
import React, {FC, useEffect} from 'react';
import {Image, ImageRequireSource, View} from 'react-native';
import {BasicObject, NavigatorScreenProps} from '@/types';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
import {
  BottomTabNavigationOptions,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import Home from './pages/home';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {Shadow} from 'react-native-shadow-2';
import Me from './pages/me';
import Invitation from '@/common-pages/invitation';
// import {toAgentApply} from '@/utils';
// import {toChat} from '@/common-pages/game-navigate';

const Tab = createBottomTabNavigator();

const homeIcon = require('@assets/icons/main-tab/home.webp');
const homeActiveIcon = require('@assets/icons/main-tab/home-active.webp');
const promotionsIcon = require('@assets/icons/main-tab/promotions.webp');
const promotionsActiveIcon = require('@assets/icons/main-tab/promotions-active.webp');
const inviteIcon = require('@assets/icons/main-tab/invite.webp');
const meIcon = require('@assets/icons/main-tab/me.webp');
const meActiveIcon = require('@assets/icons/main-tab/me-active.webp');
import IMWebView from './common-pages/im/im-webview';
import IMChat from './components/svgs/basic/im-chat';
import BetByContainer from './pages/betby/index';
import {goTo} from './utils';

const shadow = {
  startColor: '#0004',
  distance: 1,
  offset: [0, 0],
  style: [
    theme.flex.row,
    theme.flex.around,
    theme.background.white,
    theme.fill.fillW,
    {
      height: 50,
    },
  ] as BasicObject[],
} as BasicObject;

if (globalStore.isWeb) {
  shadow.distance = 0;
  delete shadow.offset;
  shadow.style.push({
    boxShadow: '0 0 1px 0px #0004',
  });
}

export const mainPageList: {
  name: string;
  link: string;
  component: (props: NavigatorScreenProps) => React.JSX.Element;
  img?: ImageRequireSource;
  activeImg?: ImageRequireSource;
  unmountOnBlur?: boolean;
  lazy?: boolean;
  params?: BasicObject;
  svgIcon?: ({active}: {active: boolean}) => React.ReactNode;
}[] = [
  {
    name: 'BetBy',
    link: 'index/betby',
    component: BetByContainer,
    img: promotionsIcon,
    activeImg: promotionsActiveIcon,
  },
  {
    name: 'Home',
    link: 'index/home',
    component: Home,
    img: homeIcon,
    activeImg: homeActiveIcon,
  },
  // {
  //   name: 'Promotions',
  //   link: 'index/promotion',
  //   component: Promotion,
  //   img: promotionsIcon,
  //   activeImg: promotionsActiveIcon,
  // },

  {
    name: 'Invite',
    link: 'index/referral',
    component: Invitation,
    img: inviteIcon,
    activeImg: inviteIcon,
  },
  {
    name: 'Chat',
    link: 'index/chat',
    component: IMWebView,
    svgIcon: ({active}) => <IMChat active={active} width={24} height={24} />,
    // unmountOnBlur: true,
    lazy: false,
  },
  {
    name: 'Me',
    link: 'index/me',
    component: Me,
    img: meIcon,
    activeImg: meActiveIcon,
  },
];

const CusDefautTab = ({
  index,
  label,
  isFocused,
  options,
  onPress,
  count,
}: {
  index: number;
  label: string;
  isFocused: boolean;
  options?: BottomTabNavigationOptions;
  onPress: (isFocused: boolean) => void;
  count?: number;
}) => {
  return (
    <NativeTouchableOpacity
      activeOpacity={0.8}
      accessibilityRole="button"
      accessibilityState={isFocused ? {selected: true} : {}}
      accessibilityLabel={options?.tabBarAccessibilityLabel}
      testID={options?.tabBarTestID}
      onPress={() => onPress(isFocused)}
      style={[
        theme.flex.center,
        theme.flex.flex1,
        theme.padding.tbs,
        theme.position.rel,
        theme.overflow.visible,
      ]}>
      <View style={[theme.position.rel]}>
        {label === 'Invite' ? (
          <>
            <Image
              style={[
                // eslint-disable-next-line react-native/no-inline-styles
                {
                  height: 54,
                  width: 54,
                  borderRadius: 25,
                  bottom: 0,
                  transform: 'translateX(-50%)',
                  left: '50%',
                },
                theme.position.abs,
              ]}
              source={mainPageList[index].activeImg!}
            />
            <View style={[theme.icon.l]} />
          </>
        ) : mainPageList[index].svgIcon ? (
          mainPageList[index].svgIcon!({active: isFocused})
        ) : (
          <Image
            style={theme.icon.l}
            source={
              isFocused
                ? mainPageList[index].activeImg!
                : mainPageList[index].img!
            }
          />
        )}
        {!!count && count > 0 && (
          <View
            style={[
              theme.flex.center,
              theme.position.abs,
              {
                paddingHorizontal: 5,
                paddingVertical: 2,
                borderWidth: 1,
                borderColor: '#FFF',
                borderRadius: 9999,
                backgroundColor: '#DC083B',
                left: 15,
                top: -4,
              },
            ]}>
            <Text fontSize={10} fontFamily="fontDin" blod color={'#FFF'}>
              {count > 99 ? '99+' : count}
            </Text>
          </View>
        )}
      </View>

      <Text
        blod={isFocused}
        fontSize={10}
        style={{
          color: isFocused ? theme.basicColor.primary : theme.basicColor.dark,
        }}>
        {label}
      </Text>
    </NativeTouchableOpacity>
  );
};

interface TabBarListItem {
  name: string;
  options?: BottomTabNavigationOptions;
}
interface TabBarListProps {
  list: TabBarListItem[];
  activeIndex: number;
}

export const TabBarList: FC<TabBarListProps> = ({list, activeIndex}) => {
  console.log(list);
  const {i18n} = useTranslation();
  const [imCount, setImCount] = React.useState<number>(0);
  useEffect(() => {
    const countStr = localStorage.getItem('imCount') || '0';
    setImCount(Number(countStr));
    const sub = globalStore.chatNumSubject.subscribe(count => {
      setImCount(count);
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);
  return (
    <Shadow containerViewProps={{id: 'tabbar-container'}} {...shadow}>
      {list.map((item, index) => {
        const label =
          item.name === 'BetBy'
            ? 'Cricket'
            : i18n.t(`home.tab.${item.name.toLocaleLowerCase()}`);
        const onPress = (isFocused: boolean) => {
          if (isFocused) {
            return;
          }
          goTo(item.name);
        };
        return (
          <CusDefautTab
            key={label}
            index={index}
            label={label}
            isFocused={activeIndex === index}
            options={item.options}
            onPress={onPress}
            count={label === 'Chat' ? imCount : 0}
          />
        );
      })}
    </Shadow>
  );
};

const MainNav = () => {
  return (
    <Tab.Navigator
      // eslint-disable-next-line react/no-unstable-nested-components
      tabBar={props => {
        return (
          <TabBarList
            list={props.state.routes.map(route => {
              const {options} = props.descriptors[route.key];

              return {
                name: route.name,
                options,
              };
            })}
            activeIndex={props.state.index}
          />
        );
      }}
      screenOptions={{
        headerShown: false,
      }}>
      {mainPageList.map(v => (
        <Tab.Screen
          key={v.name}
          name={v.name}
          component={v.component}
          options={{
            unmountOnBlur: v.unmountOnBlur,
            lazy: v.lazy,
          }}
          initialParams={v.params}
        />
      ))}
    </Tab.Navigator>
  );
};

export default MainNav;
