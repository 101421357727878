import React, {useEffect, useRef} from 'react';
import {getBetBy} from '../home.service';
import {goTo} from '@/utils';
import {useBetByInit} from '@/utils/betby';
import {homePage} from '@/config';

const HomeBetByTopMatch = () => {
  const widgetRef = useRef<any>();

  const handleInitBetbyWidget = () => {
    if (widgetRef.current != null) {
      return;
    }
    const promodiv = document.getElementById('betby-topmatch-sports');
    getBetBy().then(res => {
      widgetRef.current = new BTRenderer().initialize({
        // Brand id
        brand_id: res.brandId,
        // User token
        token: res.token,
        // Language
        lang: 'en',
        // Dom node for widget
        target: promodiv,
        // Optional theme override,
        // should not be set if you want to use your
        // default brand themes
        themeName: 'default',
        // Widget name:
        widgetName: 'top_match',
        // Params specific to widget
        widgetParams: {
          // placeholder name from backoffice
          placeholder: 'top',
          // callback to change url when user clicks on banner
          onRecharge() {
            goTo('Recharge');
          },
          onLogin() {
            goTo('Login', {backPage: homePage, sucessPage: homePage});
          },
          onRegister() {
            goTo('Login', {backPage: homePage, sucessPage: homePage});
          },
          goToClick: (args: any) => {
            goTo('BetBy', {
              'bt-path': args.url,
            });
          },
        },
      });
    });
  };

  const {login} = useBetByInit(handleInitBetbyWidget);

  useEffect(() => {
    return () => {
      const current = widgetRef.current;
      if (current != null) {
        current.kill();
        widgetRef.current = null;
      }
    };
  }, [login]);

  return <div id="betby-topmatch-sports" style={{marginTop: 8}} />;
};

export default HomeBetByTopMatch;
