import {AppRegistry} from 'react-native';
import App from './App';

if (
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_ENV !== 'test'
) {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
  console.dir = () => {};
  console.table = () => {};
  console.group = () => {};
}

AppRegistry.registerComponent('App', () => App);

AppRegistry.runApplication('App', {
  rootTag: document.getElementById('root'),
});
