import React, {useEffect, useRef} from 'react';
import {getBetBy} from '../home.service';
import {goTo} from '@/utils';
import {useBetByInit} from '@/utils/betby';

const HomeBetByPromo = () => {
  const widgetRef = useRef<any>();
  const handleInitBetbyWidget = () => {
    if (widgetRef.current != null) {
      return;
    }
    const promodiv = document.getElementById('betby-promo-sports');
    getBetBy().then(res => {
      widgetRef.current = new BTRenderer().initialize({
        // Brand id
        brand_id: res.brandId,
        // User token
        token: res.token,
        // Language
        lang: 'en',
        // Dom node for widget
        target: promodiv,
        // Optional theme override,
        // should not be set if you want to use your
        // default brand themes
        themeName: 'default',
        // Widget name:
        widgetName: 'promo',
        // Params specific to widget
        widgetParams: {
          // placeholder name from backoffice
          placeholder: 'operator_page1',
          // callback to change url when user clicks on banner
          onBannerClick: (args: any) => {
            goTo('BetBy', {
              'bt-path': args.url,
            });
          },

          // withSportsList: true,
          // callback to change url when user clicks on sport icon within sports menu
          onSportClick: (args: any) => {
            goTo('BetBy', {
              'bt-path': args.url,
            });
            // goTo('BetBy', {})
          },
        },
      });
    });
  };

  const {login} = useBetByInit(handleInitBetbyWidget);

  useEffect(() => {
    return () => {
      const current = widgetRef.current;
      if (current != null) {
        current.kill();
        widgetRef.current = null;
      }
    };
  }, [login]);
  return <div id="betby-promo-sports" />;
};

export default HomeBetByPromo;
