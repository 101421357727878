import {useEffect, useRef, useState} from 'react';
import envConfig from './env.config';
import globalStore from '@/services/global.state';

let _initedScript = false;
let willInitFns: (() => void)[] = [];

function handleInitBetby() {
  _initedScript = true;
  willInitFns.forEach(fn => fn());
  willInitFns = [];
}

export function useBetByInit(initFn: () => void) {
  const [token, setToken] = useState('');
  const debounceTimer = useRef<NodeJS.Timeout>();
  useEffect(() => {
    const sub = globalStore.tokenSubject.subscribe(_token => {
      setToken(_token || '');
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);
  useEffect(() => {
    debounceTimer.current = setTimeout(() => {
      if (!_initedScript) {
        willInitFns.push(initFn);
        if (!document.getElementById('betbyScript')) {
          const el = document.createElement('script');
          el.src = envConfig.betbyScriptUrl;
          el.id = 'betbyScript';
          document.body.insertBefore(el, null);
          el.onload = handleInitBetby;
        }
      } else {
        initFn();
      }
    }, 500);

    return () => {
      if (debounceTimer.current != null) {
        clearTimeout(debounceTimer.current);
        debounceTimer.current = undefined;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  return {
    login: !!token,
    token,
  };
}
